<template>
  <div>
    <!--<v-row>
      <v-col cols="6" md="3" class="pl-3">
        <v-autocomplete
          label="Filter By Year"
          outlined
          dense
          v-model="yearFilter"
          :items="yearList"
          class="formFields"
          color="#7253CF"
          :disabled="loading"
          @change="getOverViewData()"
        >
        </v-autocomplete>
      </v-col>
    </v-row>-->
    <v-row>
      <v-col cols="12" sm="12" md="3">
        <v-row no-gutters class="pb-3" style="height: 50%">
          <v-col>
            <v-card class="card py-1" v-if="raisedDonations">
              <v-list>
                <v-list-item>
                  <v-sheet
                    class="text-center py-2 px-3"
                    rounded
                    height="40"
                    width="40"
                    color="rgba(6, 118, 5, 0.12)"
                    style="margin-top: 22px"
                  >
                    <v-img
                      src="@/assets/DonationManagement/DonationRaisedLogo.png"
                      height="24"
                      width="19"
                    ></v-img>
                  </v-sheet>

                  <v-list-item-content>
                    <v-list-item-title class="title">
                      <p
                        style="
                          margin-top: 20px;
                          margin-left: 10px;
                          font-weight: 700;
                        "
                      >
                        ${{ raisedDonations }}
                      </p>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title style="color: #757575">
                      Total Raised
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-3" style="height: 50%">
          <v-col>
            <v-card class="card py-1" v-if="donations">
              <v-list>
                <v-list-item>
                  <v-sheet
                    class="text-center"
                    rounded
                    height="40"
                    width="40"
                    color="rgba(231, 111, 0, 0.12)"
                    style="margin-top: 22px"
                  >
                    <v-img
                      src="@/assets/DonationManagement/DonationLogo.png"
                    ></v-img>
                  </v-sheet>

                  <v-list-item-content>
                    <v-list-item-title class="title">
                      <p
                        style="
                          margin-top: 20px;
                          margin-left: 10px;
                          font-weight: 700;
                        "
                      >
                        {{ donations }}
                      </p>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title style="color: #757575">
                      No of Donation
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="paymentModeLoaded" cols="6" sm="12" md="3">
        <v-card class="graph">
          <v-card-text>
            <v-row>
              <v-col class="py-0">
                <pie-chart
                  :totalDonations="donations"
                  :dataset="payment_mode_breakup"
                  height="300px"
                ></pie-chart>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="py-6">
            <v-row justify="center">
              <v-col cols="10" class="text-center">
                <div class="graphTitle">Payment mode breakup</div>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="6" v-if="processingFeesLoaded" sm="12" md="3">
        <v-card class="graph">
          <v-card-text>
            <v-row>
              <v-col class="py-0">
                <pie-chart
                  :totalDonations="donations"
                  :dataset="processing_fees_breakup"
                  height="300px"
                ></pie-chart>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="py-6">
            <v-row justify="center">
              <v-col cols="10" class="text-center">
                <div class="graphTitle">Processing fees breakup</div>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col v-if="donorTypeLoaded" cols="6" sm="12" md="3">
        <v-card class="graph">
          <v-card-text>
            <v-row>
              <v-col class="py-0">
                <pie-chart
                  :totalDonations="donations"
                  :dataset="donor_type_breakdown"
                  height="300px"
                ></pie-chart>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="py-6">
            <v-row justify="center">
              <v-col cols="10" class="text-center">
                <div class="graphTitle">Donor type breakdown</div>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {
  API_ADMIN_DONATION_OVERVIEW,
  API_GET_FISCAL_YEAR_RANGE,
} from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
import authToken from "@/common/authToken";
import { mapGetters } from "vuex";
export default {
  name: "CardAndGraphs",
  components: {
    PieChart: () => import("@/components/Graphs/PieChart.vue"),
  },
  props: ["graphLoader"],
  data() {
    return {
      yearList: [],
      yearFilter: "",
      payment_mode_breakup: {},
      processing_fees_breakup: {},
      donor_type_breakdown: {},
      donations: null,
      raisedDonations: null,
      loaded: false,
      paymentModeLoaded: false,
      processingFeesLoaded: false,
      donorTypeLoaded: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    selectedCampaignData() {
      return this.selectedCampaign;
    },
    userTeam() {
      return authToken.decodedToken().user_team;
    },
    userType() {
      return authToken.decodedToken().user_type;
    },
    layout() {
      return this.$route.meta.layout;
    },
  },
  watch: {
    graphLoader: function () {
      if (this.graphLoader) {
        this.getOverViewData();
        this.graphLoader = false;
      }
    },
  },
  methods: {
    getOverViewData() {
      console.log("inoverview");
      const successHandler = (res) => {
        this.loaded = true;
        console.log(res.data.overview_data);
        this.donations = res.data.overview_data.total_donation;
        this.raisedDonations = res.data.overview_data.total_donation_raised;
        localStorage.setItem("schoofundraised", this.raisedDonations);
        //loading graph check
        let paymentCheck = res.data.overview_data.payment_mode_breakup.value;
        let processingFeeCheck =
          res.data.overview_data.processing_fees_breakup.value;
        let donorTypeCheck = res.data.overview_data.donor_type_breakup.value;
        if (paymentCheck[0] > 0 || paymentCheck[1] > 0 || paymentCheck[2] > 0) {
          this.paymentModeLoaded = true;
        }
        if (processingFeeCheck[0] > 0 || processingFeeCheck[1] > 0) {
          this.processingFeesLoaded = true;
        }
        if (donorTypeCheck[0] > 0 || donorTypeCheck[1] > 0) {
          this.donorTypeLoaded = true;
        }
        this.payment_mode_breakup = {};

        this.payment_mode_breakup = {
          labels: res.data.overview_data.payment_mode_breakup.label,
          datasets: [
            {
              borderWidth: 1,
              borderColor: ["#FFFFFF", "#FFFFFF", "#FFFFFF"],
              backgroundColor: [
                "rgba(6, 118, 5, 0.2)",
                "rgba(0, 133, 255, 0.2)",
                "rgba(237, 109, 17, 0.2)",
              ],
              data: res.data.overview_data.payment_mode_breakup.value,
              totalDonation: res.data.overview_data.total_donation,
            },
          ],
        };

        this.processing_fees_breakup = {
          labels: res.data.overview_data.processing_fees_breakup.label,
          datasets: [
            {
              borderWidth: 1,
              borderColor: ["#FFFFFF", "#FFFFFF", "#FFFFFF"],
              backgroundColor: [
                "rgba(6, 118, 5, 0.2)",
                "rgba(0, 133, 255, 0.2)",
                "rgba(237, 109, 17, 0.2)",
              ],
              data: res.data.overview_data.processing_fees_breakup.value,
              totalDonation: res.data.overview_data.total_donation,
            },
          ],
        };
        this.donor_type_breakdown = {
          labels: res.data.overview_data.donor_type_breakup.label,
          datasets: [
            {
              borderWidth: 1,
              borderColor: ["#FFFFFF", "#FFFFFF", "#FFFFFF"],
              backgroundColor: [
                "rgba(6, 118, 5, 0.2)",
                "rgba(0, 133, 255, 0.2)",
                "rgba(237, 109, 17, 0.2)",
              ],
              data: res.data.overview_data.donor_type_breakup.value,
              totalDonation: res.data.overview_data.total_donation,
            },
          ],
        };
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
        if (this.yearFilter) {
        formData.fiscal_year = this.yearFilter;
      }
      if (this.layout === "CampaignDashboardLayout") {
        let PANEL = this.$route.matched[0].path.substring(1);
        if (PANEL === "district") {
          formData["campaign_id"] = this.selectedCampaignData.campaignData.id;
        } else {
          formData["campaign_id"] = this.selectedCampaignData.campaignData.id;
          if (this.userTeam === "district") {
            formData["school_id"] =
              this.selectedCampaignData.organizationData.id;
          }
        }
        if (this.userTeam === "admin") {
          formData["campaign_id"] = this.selectedCampaignData.campaignData.id;
          if (this.$route.matched[0].path.substring(1) === "district") {
            formData["district_id"] =
              this.selectedCampaignData.organizationData.id;
          } else {
            formData["school_id"] =
              this.selectedCampaignData.organizationData.id;
          }
        }
      }
      Axios.request_GET(
        API_ADMIN_DONATION_OVERVIEW,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true
      );
    },
    getYearList() {
      const self = this;
      const successHandler = (res) => {
        const data = res.data;
        self.yearList = data.year_list;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      const finallyHandler = () => {};
      const formJson = {};
      return Axios.request_GET(
        API_GET_FISCAL_YEAR_RANGE,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        finallyHandler
      );
    },
  },
  mounted() {
    this.getOverViewData();
    this.getYearList();
  },
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.title {
  margin-top: 0px;
  font-size: 10px;
}
.card.v-card.v-sheet.theme--light {
  border-radius: 6px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.12);
  height: 100%;
}
.graph.v-card.v-sheet.theme--light {
  padding-top: 10px;
  border-radius: 6px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.12);
  height: 100%;
}
.v-list-item__icon {
  max-width: 50px;
  padding: 0px;
}
.v-list-item {
  width: 100%;
  display: flex;
  height: 40px;
}
.v-list-item__title.title {
  margin-top: 25px;
  width: 50px;
}
.graphTitle {
  color: #2d2d2d;
  font-weight: 600;
  font-size: 18px;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .graphTitle {
    font-size: 14px;
    padding-top: 15px;
    padding-left: 30px;
  }
  .graph.v-card.v-sheet.theme--light {
    padding-top: 20px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>
